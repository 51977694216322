<template>
  <div class="signup">
    <div class="signup-container">
      <div class="signup-form">
        <h2 class="heading">SIGN UP</h2>
        <form class="form-control">
          <Input v-model="localUser.country" label="Country" class="form-input" isDisabled/>
          <Select v-model="selectedState" label="State" name ="State Selection" class="form-input" :allOptions="stateList" placeholderText="Select State" v-on:change="changeState"/>
          <Select v-if="selectedState" name ="City Selection" v-model="selectedCity" label="City" class="form-input" :allOptions="cityList" placeholderText="Select City" v-on:change="changeCity"/>
          <Input v-model="localUser.name" label="Name" class="form-input" placeHolderText="Enter name"
          isRequired/>
          <Input v-model="localUser.email" label="Email" class="form-input" placeHolderText="Enter email"/>
          <Input v-model="localUser.contactNumber" label="Contact Number" class="form-input" placeHolderText="Enter phonenumber"/>
          <Input v-model="localUser.password" label="Password" placeHolderText="Enter password" class="form-input" type="password" isRequired />
          <Input v-model="confirmPassword" label="Confirm Password" placeHolderText="Confirm password" class="form-input" type="password" isRequired :hasError="validatePasswords()" errorMessage="Passwords dont match"/>
        </form>
        <div class="signup-button">
          <Button
            @click="register"
            label="Register"
            :disabled="hasError() || isLoading"
            flex-start>
          </Button>
        </div>
      </div>
      <div class="signup-image-class">
        <img
              class="signup-image"
              :src="signupImage"
              alt="Signup"
          />
        <div class="member-button">
          <router-link to="Login">
            <Button
              label="I am already a member"
              flex-start
              type="outlined">
            </Button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../shared/components/Button.vue'
import Input from '../../shared/components/Input.vue'
import Select from '../../shared/components/Select.vue'
import { isNotNullNorEmpty, isNullOrUndefined } from '../../shared/utils/Objects'
import { defaultToast, ToastType } from '../../../utils/toastUtils'
import { mapActions } from 'vuex'

export default {
  name: 'Signup',
  created () {
    this.loadDefaultCountrySelection()
  },
  components: {
    Input,
    Button,
    Select
  },
  data () {
    return {
      localUser: {
        country: '',
        state: '',
        name: '',
        email: '',
        contactNumber: '',
        registrationNumber: '',
        password: '',
        selectedHierarchy: ''
      },
      confirmPassword: '',
      isLoading: false,
      countryId: '',
      stateList: [],
      selectedState: '',
      cityList: [],
      selectedCity: ''
    }
  },
  computed: {
    signupImage () {
      return require(process.env.VUE_APP_REGISTRATION_IMAGE)
    }
  },
  methods: {
    ...mapActions('Signup', ['registerUser', 'loadDefaultHierarchy', 'loadHierarchyChildren']),
    async register () {
      this.isLoading = true
      if (isNotNullNorEmpty(this.localUser.name.trim()) && isNotNullNorEmpty(this.localUser.password.trim() && isNotNullNorEmpty(this.localUser.selectedHierarchy))) {
        const params = {
          username: this.localUser.name,
          hierarchyId: this.localUser.selectedHierarchy,
          ssoUserToBeCreated: true,
          password: this.localUser.password,
          contactNumber: this.localUser.contactNumber,
          emailId: this.localUser.email
        }
        const response = await this.registerUser({ params: params })
        this.isLoading = false
        if (!isNullOrUndefined(response) && response.success) {
          defaultToast(ToastType.Success, 'Signup Successful!')
          this.$router.push('login')
        }
      }
    },
    validatePasswords () {
      var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{8,20}$/
      return isNotNullNorEmpty(this.confirmPassword) && !regex.test(this.confirmPassword) && this.localUser.password !== this.confirmPassword
    },
    hasError () {
      return (this.localUser.name.trim().length < 1) || (this.localUser.password.trim().length < 1) || this.validatePasswords()
    },
    async loadDefaultCountrySelection () {
      const response = await this.loadDefaultHierarchy()
      this.localUser.country = response.name
      this.countryId = response.id
      this.loadStateSelection()
    },
    async loadStateSelection () {
      const params = {
        hierarchyId: this.countryId
      }
      const response = await this.loadHierarchyChildren({ params: params })
      for (const state in response) {
        const obj = { Key: response[state].id, Value: response[state].name }
        this.stateList.push(obj)
      }
    },
    async changeState () {
      const params = {
        hierarchyId: this.selectedState.Data.Key
      }
      const response = await this.loadHierarchyChildren({ params: params })
      for (const state in response) {
        const obj = { Key: response[state].id, Value: response[state].name }
        this.cityList.push(obj)
      }
    },
    changeCity () {
      this.localUser.selectedHierarchy = this.selectedCity.Data.Key
    }
  }
}
</script>

<style lang='scss' scoped>
.form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
}
.centered{
    text-align: center;
}
.signup-container{
  border: 1px solid var(--hr-color);
  padding: 20px;
  max-width: 1200px;
  display: flex;
  background-color: var(--form-part-color);
  box-shadow: 1px 1px var(--hr-color);
}
.form-control{
  padding-bottom: 20px;
  padding-left: 10px;
  padding-top: 10px;
  box-shadow: none;
}
.form-input{
  padding: 5px;
  box-shadow: none;
}
 @media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }
  .form-group .input-control {
    margin-top: 2px;
  }
}
.heading {
  margin-top: 45px;
  color: var(--primary-theme);
  margin-bottom: 30px;
  padding-left: 150px;
}
.signup-image-class {
  padding-top: 48px;
  padding-right: 34px;
}
.signup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-form {
    margin-left: 55px;
    margin-right: 45px;
    padding-left: 24px;
}
.signup-image-link {
    font-size: 14px;
    display: block;
    text-align: center;
    text-decoration: underline;
    padding-bottom: 50px;
    color: var(--primary-theme);
}
.member-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}
.signup-button {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}
.signup-image {
  padding: 20px;
  max-height: 460px;
  width: auto;
}
</style>
